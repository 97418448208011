import React from 'react';
import { CreateSigninRequestArgs, OidcClient, SigninResponse, WebStorageStateStore } from 'oidc-client-ts';
import { UserProfile } from '../../types';
import AppConfig from '../../AppConfig.ts';
import userProfileMock, { mockAccessTokens } from '../../mocks/data/userInfo.ts';
import { AppRoutes } from '../../routes/App.tsx';

enum ApiResource {
  ORGANIZATION_API = 'organizationApis',
  IDENTITY_API = 'identityApis',
}
export const organizationTokenSignIn: Partial<CreateSigninRequestArgs> = {
  resource: AppConfig.organizationsApiUrl,
  scope: 'openid email profile membership organizations.manage',
  state: ApiResource.ORGANIZATION_API, // Used to differentiate the callbacks state, is returned by OIDC as userState
};

export const identityApiTokenSignIn: Partial<CreateSigninRequestArgs> = {
  resource: AppConfig.launchpadUrl,
  scope: 'openid email profile membership users.manage',
  state: ApiResource.IDENTITY_API, // Used to differentiate the callbacks state, is returned by OIDC as userState
};

interface UserContextType {
  userProfile: UserProfile | null;
  organizationApiAccessToken: string;
  identityApiAccessToken: string;
  isSessionLoading: boolean;
  oidcError: string | null;
}

const UserContext = React.createContext<UserContextType | undefined>(undefined);

export const useUser = (): UserContextType => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a OidcProvider');
  }
  return context;
};

export const OIDC_CALLBACK_PATH = '/oidc/callback';
const LOGIN_CALLBACK_PATH = '/login/callback';
const client = new OidcClient({
  authority: AppConfig.oidcClientUrl,
  client_id: 'organization_settings',
  redirect_uri: `${window.location.origin}${OIDC_CALLBACK_PATH}`,
  response_type: 'code',
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
});

const defaultState: UserContextType = {
  userProfile: null,
  organizationApiAccessToken: '',
  identityApiAccessToken: '',
  isSessionLoading: true,
  oidcError: '',
};

const sessionKey = 'user_session';

const storageHelper = (
  action: 'get' | 'set' | 'remove',
  sessionData: UserContextType = defaultState,
): UserContextType => {
  switch (action) {
    case 'get':
      const sessionDataStr = window.sessionStorage.getItem(sessionKey);
      // If sessionData is not present in the sessionStorage we want to set it to defaultSessionData
      try {
        return sessionDataStr ? JSON.parse(sessionDataStr) : storageHelper('set', defaultState);
      } catch {
        return storageHelper('set', defaultState);
      }
    case 'set':
      window.sessionStorage.setItem(sessionKey, JSON.stringify(sessionData));
      return sessionData;
    case 'remove':
      window.sessionStorage.removeItem(sessionKey);
      return sessionData;
  }
};

export default function OidcProvider({ children }: { children: React.ReactNode }): JSX.Element | null {
  const [state, setState] = React.useState<UserContextType>(storageHelper('get'));
  const updateState = React.useCallback((updates: Partial<UserContextType>): UserContextType => {
    const updatedState = { ...state, ...updates };
    if (updatedState.oidcError || (updatedState.identityApiAccessToken && updatedState.organizationApiAccessToken)) {
      updatedState.isSessionLoading = false;
    }
    storageHelper('set', updatedState);
    setState(updatedState);
    return updatedState;
  }, []);

  const handleSignInCallback = async () => {
    const signInResponse: SigninResponse = await client.processSigninResponse(window.location.href);
    const { access_token: accessToken, profile, userState } = signInResponse;
    const stateUpdates: Partial<UserContextType> = {};

    switch (userState) {
      case ApiResource.ORGANIZATION_API:
        stateUpdates.organizationApiAccessToken = accessToken;
        break;
      case ApiResource.IDENTITY_API:
        stateUpdates.identityApiAccessToken = accessToken;
        break;
      default:
        console.error('Unsupported state:', userState);
        break;
    }

    stateUpdates.userProfile = profile as UserProfile;
    const updatedState = updateState(stateUpdates);
    const canAccessSpa = profile.org_admin && profile.subdomain === AppConfig.orgSubdomain;
    const replacePath = canAccessSpa ? `/${AppRoutes.ROOT_PATH}` : `/${AppRoutes.UNAUTHORIZED}`;
    if (!updatedState.isSessionLoading) {
      window.history.replaceState({}, document.title, replacePath);
    } else {
      await initiateSignin(updatedState);
    }
  };

  const initiateSignin = async (currentState: UserContextType) => {
    let authUrl = '';
    if (!currentState.identityApiAccessToken) {
      authUrl = await client.createSigninRequest(identityApiTokenSignIn).then(({ url }) => url);
    } else if (!currentState.organizationApiAccessToken) {
      authUrl = await client.createSigninRequest(organizationTokenSignIn).then(({ url }) => url);
    }
    if (authUrl) {
      window.location.assign(authUrl);
    }
  };
  const handleOidcSignIn = async () => {
    try {
      if (window.location.pathname === OIDC_CALLBACK_PATH || window.location.pathname === LOGIN_CALLBACK_PATH) {
        return await handleSignInCallback();
      }
      await initiateSignin(state);
    } catch (error) {
      const errorResponse = error as { error: string };
      updateState({ oidcError: errorResponse.error });
      console.error('Could not get OIDC user profile:', JSON.stringify(error));
      window.history.replaceState({}, document.title, `/${AppRoutes.UNAUTHORIZED}`);
    }
  };

  const mockValidOIDCSession = () => {
    window.history.replaceState({}, document.title, `/${AppRoutes.ROOT_PATH}`);
    updateState({
      userProfile: userProfileMock,
      organizationApiAccessToken: mockAccessTokens.organizationApi,
      identityApiAccessToken: mockAccessTokens.identityApi,
    });
  };

  React.useEffect(() => {
    if (AppConfig.isLocal) {
      mockValidOIDCSession();
    } else {
      handleOidcSignIn();
    }
    const handleBeforeUnload = () => {
      if (!storageHelper('get').isSessionLoading) {
        storageHelper('remove');
      }
    };

    const unmount = () => window.removeEventListener('beforeunload', handleBeforeUnload);

    window.addEventListener('beforeunload', handleBeforeUnload);
    return unmount;
  }, []);

  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}
